<template>
  <div class="w-full">
    <div class="py-4 gap-3 overflow-auto scrollbar-hide scrolling-touch" v-if="groups">
      <div
        ref="groupRef"
        class="flex justify-start flex-wrap"
        :style="`min-width: ${categoriesMinWidth}px`"
      >
        <div v-if="selectAll && showSelectAtFirst">
          <div @click="selectCategory(null)"
             class="group p-2 cursor-pointer overflow-hidden flex items-center justify-center"
             :class="{[itemActiveClass]: !selectedGroupId, [itemClass] : true}"
             :style="{'border-color': activeBorderColor(group)}"
          >
            <div class="truncate font-semibold">
              {{ $t("translations.components.modal-item-select.all-items") }}
            </div>
          </div>
          <div class="menu-border"></div>
        </div>
        <div class="" v-for="(group, index) in groups" :key="index" ref="categoryItem">
          <div @click="selectCategory(group)"
             class="group p-2 cursor-pointer overflow-hidden flex items-center justify-center"
             :class="{
              [itemActiveClass]: selectedGroupId === group.id,
              [itemClass]: true
            }"
             :style="{'border-color': activeBorderColor(group)}"
          >
            <div class="truncate font-semibold">{{ group.name }}</div>
          </div>
          <div class="menu-border"></div>
        </div>
        <div v-if="selectAll && !showSelectAtFirst">
          <div @click="selectCategory(null)"
            class="group p-2 cursor-pointer overflow-hidden flex items-center justify-center"
            :class="{[itemActiveClass]: !selectedGroupId, [itemClass] : true}"
            :style="{'border-color': activeBorderColor(group)}"
          >
            <div class="truncate font-semibold">
              {{ $t("translations.components.modal-item-select.all-items") }}
            </div>
          </div>
          <div class="menu-border"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollIntoView from "scroll-into-view-if-needed"

const scrollToSelectedGroup = (groupRef, index) => {
  const el = groupRef?.children?.[index] ?? null
  if (!el) return

  scrollIntoView(el, {
    behavior: "smooth",
    scrollMode: "if-needed",
    block: "nearest",
    inline: "nearest",
  })
}

export default {
  name: "CategoryList",
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    selectedGroupId: {
      type: [Number],
      default: null
    },
    borderActiveColor: {
      type: String,
      default: ''
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    itemClass: {
      type: String,
      default: ''
    },
    itemActiveClass: {
      type: String,
      default: ''
    },
    showSelectAtFirst: {
      type: Boolean,
      default: true
    },
    disableAutoScroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categoriesMinWidth: 0
    }
  },
  created () {
    setTimeout(() => {  this.handleResize() }, 500)
  },
  mounted () {
    this.$watch(() => this.selectedGroupId,() => {
      if (this.disableAutoScroll) return
      const index = !this.selectedGroupId
        ? this.groups.length
        : this.groups.findIndex((g) => String(g.id) === String(this.selectedGroupId))

        scrollToSelectedGroup(this.$refs.groupRef, index)
      }
    )

    setTimeout(() => {  this.handleResize() }, 100)
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize)
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      if(this.$refs.categoryItem && this.$refs.categoryItem.length) {
        const windowWidth = window.innerWidth
        const totalWidth = this.$refs.categoryItem.reduce((acc, item) => acc + item.clientWidth, 0)
        const categoriesMinWidth = totalWidth / 2 + this.$refs.categoryItem[0].clientWidth

        this.categoriesMinWidth = (windowWidth < totalWidth && windowWidth > categoriesMinWidth) ? windowWidth : categoriesMinWidth
      }
    },
    selectCategory(group) {
      this.$emit('selectCategory', group)
    },
    activeBorderColor(group) {
      if (this.borderActiveColor) {
        return this.borderActiveColor
      }
      return group?.color
    }
  }
}
</script>
